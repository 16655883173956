import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from './base'

const authSlice = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["auth"] ,
  endpoints: (builder) => ({
    login: builder.mutation({
      query(body) {
        return {
          url: `login`,
          method: `POST`,
          body,
        };
      },
      invalidatesTags: ['auth'],
    }),
  }),
});
export default authSlice;
export const { useLoginMutation } = authSlice;
