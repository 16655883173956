import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./base";

const clientsSlice = createApi({
  reducerPath: "clientsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["clients"],
  endpoints: (builder) => ({
    getAll: builder.query({
      query: () => `client`,
      providesTags: ["clients"],
    }),
    addClient: builder.mutation({
      query(body) {
        return {
          url: `client`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["clients"],
    }),
    updateClient: builder.mutation({
      query({ id, body }) {
        return {
          url: `client/${id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["clients"],
    }),
    appendImageToClient: builder.mutation({
      query({ id, body }) {
        return {
          url: `client/${id}/upload_image`,
          method: "POST",
          responseHandler: "text",
          body: body,
        };
      },
      invalidatesTags: ["clients"],
    }),
    removeClient: builder.mutation({
      query(id) {
        return {
          url: `client/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["clients"],
    }),
  }),
});

export default clientsSlice;
export const {
  useGetAllQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useAppendImageToClientMutation,
  useRemoveClientMutation,
} = clientsSlice;
