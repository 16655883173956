import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
};


const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
      login(state, action) {
        return { ...state,  token: action.payload  };
      },
      logout(state) {
        return { ...state,  token: null  };
      },

    },
  });

  export const { login, logout } = authSlice.actions;
export default authSlice.reducer;