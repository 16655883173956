import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./DataSlice";
import authSlice from "./api/auth";
import serviceSlice from "./api/services";
import clientSlice from "./api/clients";
import workSLice from "./api/recentWork";
import categoriesSlice from './api/categories'
import productsSlice from "./api/products";
import authDataSlice from "./slices/authSlice";
import toastSlice from "./slices/toastSlice";

const store = configureStore({
  reducer: {
    data: dataReducer,
    auth: authDataSlice,
    toast: toastSlice,
    authApi: authSlice.reducer,
    servicesApi: serviceSlice.reducer,
    clientsApi:clientSlice.reducer,
    workseApi:workSLice.reducer,
    categoriesApi :categoriesSlice.reducer,
    productsApi:productsSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authSlice.middleware,
      serviceSlice.middleware,
      clientSlice.middleware,
      workSLice.middleware,
      categoriesSlice.middleware,
      productsSlice.middleware
    ),
});

export default store;
