import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Loader from "./Loader";
import { useSelector} from "react-redux";
const Guard = ({ children, canActivated, redirectOnRejection = "/" }) => {
  const [status, setStatus] = useState("pending");
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    Promise.all(canActivated.map((fun) => fun(auth)))
      .then((res) => {
        setStatus("fulfilled");

      })
      .catch((error) => {
        setStatus("rejected");
      });
  }, [auth, canActivated]);
  return status === "pending" ? (
    <Loader />
  ) : status === "fulfilled" ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectOnRejection} />
  );
};

export default Guard;
