import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./base";

const categoriesSlice = createApi({
  reducerPath: "categoriesApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["categories"],
  endpoints: (builder) => ({
    getAll: builder.query({
      query: () => `category`,
      providesTags: ["categories"],
    }),
    addCategory: builder.mutation({
      query(body) {
        return {
          url: `category`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["categories"],
    }),
    updateCategory: builder.mutation({
      query({ id, body }) {
        return {
          url: `category/${id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["categories"],
    }),
    appendImageToCategory: builder.mutation({
      query({ id, body }) {
        return {
          url: `category/${id}/upload_image`,
          method: "POST",
          responseHandler: 'text',
          body,
        };
      },
      invalidatesTags: ["categories"],
    }),
    removeCategory: builder.mutation({
      query(id) {
        return {
          url: `category/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["categories"],
    }),
  }),
});

export default categoriesSlice;
export const {
  useGetAllQuery,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useAppendImageToCategoryMutation,
  useRemoveCategoryMutation,
} = categoriesSlice;
