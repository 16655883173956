import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./base";

const recentWorkSlice = createApi({
  reducerPath: "workseApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["our-work"],
  endpoints: (builder) => ({
    getAll: builder.query({
      query: () => `our-work`,
      providesTags: ["our-work"],
    }),
    addWork: builder.mutation({
      query(body) {
        return {
          url: `our-work`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["our-work"],
    }),
    updateWork: builder.mutation({
      query({ id, body }) {
        return {
          url: `our-work/${id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["our-work"],
    }),
    appendImageToWork: builder.mutation({
      query({ id, body }) {
        return {
          url: `our-work/${id}/upload_image`,
          method: "POST",
          responseHandler: "text",
          body,
        };
      },
      invalidatesTags: ["our-work"],
    }),
    removeWork: builder.mutation({
      query(id) {
        return {
          url: `our-work/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["our-work"],
    }),
  }),
});

export default recentWorkSlice;
export const {
  useGetAllQuery,
  useAddWorkMutation,
  useUpdateWorkMutation,
  useAppendImageToWorkMutation,
  useRemoveWorkMutation,
} = recentWorkSlice;
