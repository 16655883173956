import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./base";

const productsSlice = createApi({
  reducerPath: "productsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["products"],
  endpoints: (builder) => ({
    getAll: builder.query({
      query: (params = {}) => ({
        url: `product`,
        params,
      }),
      providesTags: ["products"],
    }),
    addProduct: builder.mutation({
      query(body) {
        return {
          url: `product`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["products"],
    }),
    updateProduct: builder.mutation({
      query({ id, body }) {
        return {
          url: `product/${id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["products"],
    }),
    appendImageToProduct: builder.mutation({
      query({ id, body,params = {} }) {
        return {
          url: `product/${id}/upload_image`,
          method: "POST",
          responseHandler: "text",
          body,
          params
        };
      },
      invalidatesTags: ["products"],
    }),
    removeProduct: builder.mutation({
      query(id) {
        return {
          url: `product/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["products"],
    }),
  }),
});

export default productsSlice;
export const {
  useGetAllQuery,
  useAddProductMutation,
  useUpdateProductMutation,
  useAppendImageToProductMutation,
  useRemoveProductMutation,
} = productsSlice;
