import React from "react";
import Logo from "../assets/logo.png";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { getLocalImageByName } from "../utils";
import {motion} from 'framer-motion'
function Sidebar() {
  const sidebar = useSelector((state) => state.data.sidebar);
  const navigate = useNavigate();
  const logoutAction = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <motion.section initial={{opacity:0,x:-150}} animate={{opacity:1,x:0}} className="side-bar-container">
      <motion.header initial={{opacity:0,scale:0}} animate={{opacity:1,scale:1,transition:{delay:0.2}}}>
        <img  src={Logo} alt={sidebar.title} />
        <h3>{sidebar.title}</h3>
      </motion.header>
      <article className="links">
        {sidebar.links.map(({ value, route }) => {
          return (
            <NavLink
              key={value}
              className={({ isActive }) => (isActive ? "active" : "")}
              to={route}
            >
              {value}
            </NavLink>
          );
        })}
      </article>
      <footer>
        <button onClick={logoutAction}>
          <img
            src={getLocalImageByName(sidebar.button.img_url)}
            alt={"logout icon"}
          />
          <p> {sidebar.button.text}</p>
        </button>
      </footer>
    </motion.section>
  );
}

export default Sidebar;
