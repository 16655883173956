import React, { useEffect, useState } from "react";
import LoginBg from "../assets/login-bg.png";
import logo from "../assets/logo.png";
import { useSelector, useDispatch } from "react-redux";
import {
  generateFormControl,
  getStatusFromInputArray,
  handleErrors,
  updateFormError,
} from "../utils";
import { useNavigate } from "react-router-dom";
import isNotLogin from "../guards/IsNotLogin";
import Loader from "../components/Loader";
import { useLoginMutation } from "../redux/api/auth";
import { showToast } from "../redux/slices/toastSlice";
import { login } from "../redux/slices/authSlice";
function Login() {
  const navigate = useNavigate();
  const { login: loginData, } = useSelector((state) => state.data);
  const { auth } = useSelector((state) => state);
  const [formData, setFormData] = useState(
    getStatusFromInputArray(loginData.form)
  );
  const [formError, setFormError] = useState(
    getStatusFromInputArray(loginData.form)
  );
  const [loginMutation, { isLoading, isError, error }] = useLoginMutation();
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = formData;
    if (username && password) {
      try {
        const { data: reponse } = await loginMutation({ username, password });
        localStorage.setItem("token", reponse.access_token);
        dispatch(login(reponse.access_token));
        navigate("/products");
      } catch (err) {}
    }
    setFormError({
      ...formError,
      ...updateFormError(formData),
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await isNotLogin(auth);
      } catch (error) {
        navigate("/products");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(showToast({ detail: handleErrors(error) }));
    }
  }, [error, isError, dispatch]);

  return (
    <section className="login-container">
      {isLoading && <Loader />}

      <article className="background">
        <div className="left">
          <img src={LoginBg} alt="login-bg" />
        </div>
      </article>
      <article className="form-container">
        <img src={logo} alt="TopLink Logo" />

        <div>
          <h3>{loginData.title[0]}</h3>
          <h3>{loginData.title[1]}</h3>
        </div>

        <form onSubmit={handleSubmit}>
          {loginData.form.map((formControl) =>
            generateFormControl(formControl, formData, setFormData, formError)
          )}

          <div className="btn-container">
            <button disabled={isLoading}>{loginData.button.text}</button>
          </div>
        </form>
      </article>
    </section>
  );
}

export default Login;
