import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import CheckUser from "./guards/CheckUser";
import Sidebar from "./components/Sidebar";
import Guard from "./components/Guard"
const CategoriesTable = React.lazy(() =>
  import("./pages/categories/CategoriesTable")
);

const CategoriesForm = React.lazy(() =>
  import("./pages/categories/CategoriesForm")
);

const ClientsTable = React.lazy(() => import("./pages/clients/ClientsTable"));

const ProductsForm = React.lazy(() => import("./pages/products/ProductsForm"));
const ProductsTable = React.lazy(() =>
  import("./pages/products/ProductsTable")
);

const RecentWorkTable = React.lazy(() =>
  import("./pages/recentWork/RecentWorkTable")
);

const ServicesTable = React.lazy(() =>
  import("./pages/services/ServicesTable")
);



// const ClientsForm = React.lazy(() => import("./pages/clients/ClientsForm"));
// const RecentWorkForm = React.lazy(() =>
//   import("./pages/recentWork/RecentWorkForm")
// );

// const ServicesForm = React.lazy(() => import("./pages/services/ServicesForm"));

const wrapWithSidebar = (Page) => (
  <Guard canActivated={[CheckUser]}>
    <section className="main-container">
      <Sidebar />
      <Page />
    </section>
  </Guard>
);

const Routers = createBrowserRouter([
  { path: "/", element: <Login /> },
  // {
  // element: <Guard canActivated={[CheckUser]} />,
  // children: [
  {
    path: "/categories",
    element: wrapWithSidebar(CategoriesTable),
  },
  // { path: "/categories/create", element: wrapWithSidebar(CategoriesForm) },
  {
    path: "/categories/edit/:categoryId",
    element: wrapWithSidebar(CategoriesForm),
  },

  {
    path: "/products",
    element: wrapWithSidebar(ProductsTable),
  },
  { path: "/products/create", element: wrapWithSidebar(ProductsForm) },
  {
    path: "/products/edit/:productId",
    element: wrapWithSidebar(ProductsForm),
  },

  {
    path: "/recentWork",
    element: wrapWithSidebar(RecentWorkTable),
  },

  {
    path: "/clients",
    element: wrapWithSidebar(ClientsTable),
  },
  {
    path: "/services",
    element: wrapWithSidebar(ServicesTable),
  },
  // ],
  // },

  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);

export default Routers;
