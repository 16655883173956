import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    detail: "error",
    severity: "error",
    summary: "Error",
  },
  show: false,
};

const toastSlice = createSlice({
  name: "toastSlice",
  initialState,
  reducers: {
    showToast(state, action) {
      return { ...state,show:true, data: action.payload };
    },
  },
});

export const { showToast } = toastSlice.actions;
export default toastSlice.reducer;
