
const CheckUser = (auth) => {
   
    return new Promise( ( resolve, reject ) => {
        // const isUserLogin = localStorage.getItem('token')
        const isUserLogin = auth.token

        if ( isUserLogin ) {
            resolve( "User Login" )
        }
        else {
            reject( new Error( "UnAuthenticated User" ) )
        }
    } )
}

export default CheckUser