import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { logout } from "../slices/authSlice";
import { showToast } from "../slices/toastSlice";
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getState().auth.token || localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    api.dispatch(logout());
    api.dispatch(showToast({ detail: `UnAuthorized` }));
  }

  return result;
};

export default baseQueryWithReauth;
