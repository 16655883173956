import React from "react";
import logo from "../assets/logo.png";
import BarLoader from "react-spinners/BarLoader";
function Loader() {
  return <section className="loader-container">
    <img src={logo} alt={'TopLink Logo'}/>
    <BarLoader  color={'#fff'} />
  </section>;
}

export default Loader;
