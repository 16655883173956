import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  login: {
    title: [`Dashboard`, "Sign in"],
    form: [
      {
        key: "username",
        label: "Username",
        placeholder: `Enter username`,
        type: "text",
      },
      {
        key: "password",
        label: "Password",
        placeholder: `********`,
        type: "password",
      },
    ],
    button: {
      text: `LOGIN`,
    },
  },
  sidebar: {
    title: `TopLink Dashboard`,
    links: [
      { value: "Shop Items", route: "/products" },
      { value: "Shop Categories", route: "/categories" },
      { value: "Recen Work", route: "/recentWork" },
      { value: "Clients", route: "/clients" },
      { value: "Services", route: "/services" },
    ],
    button: {
      text: `Logout`,
      img_url: "logout-btn-icon.png",
    },
  },
  services: {
    title: `Services`,
    addButton: {
      icon: "pi pi-plus",
      text: `Add Service`,
    },
    columns: [
      { name: "Service Name", type: "value", key: "name" },
      { name: "Edit", type: "action", key: "edit", icon: "pi pi-pencil" },
      { name: "Remove", type: "action", key: "remove", icon: "pi pi-trash" },
    ],
    data: [
      { name: `Queue Systems` },
      { name: `Infrastructure` },
      { name: `Networking` },
      { name: `Software Solutions` },
      { name: `Smart Home` },
    ],
  },
  clients: {
    title: `Clients`,
    addButton: {
      icon: "pi pi-plus",
      text: `Add Client`,
    },
    columns: [
      { name: "Client Name", type: "value", key: "name" },
      { name: "Edit", type: "action", key: "edit", icon: "pi pi-pencil" },
      { name: "Remove", type: "action", key: "remove", icon: "pi pi-trash" },
    ],
    data: [
      { name: `Khalil Pharmacy` },
      { name: `Hafez Royal` },
      { name: `Networking` },
      { name: `Suez` },
    ],
  },
  recentWork: {
    title: `Recent Work`,
    addButton: {
      icon: "pi pi-plus",
      text: `Add Project`,
    },
    columns: [
      { name: "Title", type: "value", key: "name" },
      { name: "Edit", type: "action", key: "edit", icon: "pi pi-pencil" },
      { name: "Remove", type: "action", key: "remove", icon: "pi pi-trash" },
    ],
    data: [
      { name: `Networking and Infrastructure for Khalil Pharmacy` },
      { name: `Server Installation  at Hafez Royal` },
      { name: `Smart Factory Setup Suez  Company` },
    ],
  },
  categories: {
    title: `Shop Categories`,
    addButton: {
      icon: "pi pi-plus",
      text: `Add Category`,
    },
    columns: [
      { name: "Category Name", type: "value", key: "name" },
      { name: "Edit", type: "action", key: "edit", icon: "pi pi-pencil" },
      { name: "Remove", type: "action", key: "remove", icon: "pi pi-trash" },
    ],
    data: [
      { name: `Laptops & PCs` },
      { name: `Servers` },
      { name: `Printers` },
      { name: `UPs` },
      { name: `Networks` },
      { name: `Light Current` },
      { name: `Interactive Boards` },
      { name: `Smart Home` },
      { name: `Smart Factory` },
    ],
  },
  products: {
    title: `Shop Items`,
    addButton: {
      icon: "pi pi-plus",
      text: `Add Item`,
    },
    columns: [
      { name: "Product Name", type: "value", key: "name" },
      { name: "Category", type: "value", key: "category" },
      { name: "Edit", type: "action", key: "edit", icon: "pi pi-pencil" },
      { name: "Remove", type: "action", key: "remove", icon: "pi pi-trash" },
    ],
    data: [
      {
        category: "Laptops & PCs",
        name: `Lenovo 15.6" IdeaPad 1 Laptop, AMD Dual-core Processor, 15.6" HD Anti Lenovo 15.6" IdeaPad 1 Laptop, AMD Dual-core Processor, 15.6" HD Anti`,
      },
      {
        category: "Laptops & PCs",
        name: `SAMSUNG 16” Galaxy Book3 Pro`,
      },
      {
        category: "Laptops & PCs",
        name: `Dell G16 7620 Gaming Laptop`,
      },
      {
        category: "Laptops & PCs",
        name: `CyberpowerPC Gamer Xtreme VR Gaming PC`,
      },
      {
        category: "Servers",
        name: `Dell Optiplex 7040 Business`,
      },
      {
        category: "Laptops & PCs",
        name: `Dell G16 7620 Gaming Laptop`,
      },
      {
        category: "Servers",
        name: `OptiPlex 7000 Tower`,
      },
      {
        category: "Servers",
        name: `Dell PREC 3240 Compact I7/2.9`,
      },
      {
        category: "Servers",
        name: `CurBot Smart Curtains Rod Opener, Smart Home Automatic Curtain Swi`,
      },
      {
        category: "Smart Home",
        name: `CurBot Smart Curtains Rod Opener, Smart Home Automatic Curtain Swi`,
      },
      {
        category: "Smart Home",
        name: `CurBot Smart Curtains Rod Opener, Smart Home Automatic Curtain Swi`,
      },
      {
        category: "Smart Home",
        name: `CurBot Smart Curtains Rod Opener, Smart Home Automatic Curtain Swi`,
      },
      {
        category: "Smart Home",
        name: `CurBot Smart Curtains Rod Opener, Smart Home Automatic Curtain Swi`,
      },
      {
        category: "Smart Home",
        name: `CurBot Smart Curtains Rod Opener, Smart Home Automatic Curtain Swi`,
      },
      {
        category: "Smart Home",
        name: `CurBot Smart Curtains Rod Opener, Smart Home Automatic Curtain Swi`,
      },
      {
        category: "Smart Home",
        name: `CurBot Smart Curtains Rod Opener, Smart Home Automatic Curtain Swi`,
      },
    ],
  },
  serviceForm: {
    form: [
      {
        key: "name",
        label: "Name",
        placeholder: `Service Name`,
        type: "text",
      },
      {
        key: "description",
        label: "Description paragraph",
        placeholder: "Descripe Your Service ...",
        type: "textarea",
        maxLength: 120,
      },
    ],
    addNewBullet: { icon: "pi pi-plus", text: `Add Bullet` },
    button: {
      text: `Save`,
    },
    addService: {
      title: "New Service",
      successToastMessage: `Service Added Successfully`,
    },
    updateService: {
      title: "Update Service",
      successToastMessage: `Service Updated Successfully`,
    },
    deleteService: {
      confirmText: "Delete",
      cancleText: "Back",
      successToastMessage: `Removed Successfully`,
    },
  },
  clientsForm: {
    form: [
      {
        key: "name",
        label: "Client Name",
        placeholder: `Write client/company name`,
        type: "textarea",
      },
      {
        key: "category",
        label: "Client Category",
        placeholder: `Write client/company type`,
        type: "text",
      },
    ],
    button: {
      text: `Save`,
    },
    addClient: {
      title: `New Client`,
      successToastMessage: `Client Added Successfully`,
    },
    updateClient: {
      title: `Update Client`,
      successToastMessage: `Client Updated Successfully`,
    },
    deleteClient: {
      dialogHeader: `Delete The Client?`,
      confirmText: "Delete",
      cancleText: "Back",
      successToastMessage: `Removed Successfully`,
    },
  },
  recentWorkForm: {
    form: [
      {
        key: "name",
        label: "Title",
        placeholder: `Project and company name`,
        type: "text",
      },
    ],
    addNewBullet: { icon: "pi pi-plus", text: `Add Bullet` },
    button: {
      text: `Save`,
    },
    addWork: {
      title: `New Project`,
      successToastMessage: `Project Added Successfully`,
    },
    updateWork: {
      title: `Update Project`,
      successToastMessage: `Project Updated Successfully`,
    },
    deleteWork: {
      dialogHeader: `Delete  the Project?`,
      confirmText: "Delete",
      cancleText: "Back",
      successToastMessage: `Removed Successfully`,
    },
  },
  categoriesForm: {
    title: `Adding New Category`,
    form: [
      {
        key: "name",
        label: "Category Name",
        placeholder: `Category name...`,
        type: "text",
      },
    ],
    button: {
      add: { text: `Add` },
      update: { text: `Update` },
    },
    addCategory: {
      title: `Adding New Category`,
      successToastMessage: `Category Added Successfully`,
    },
    updateCategory: {
      title: `Update Category`,
      successToastMessage: `Category Updated Successfully`,
    },
    deleteCategory: {
      warningText: "WARNING",
      dialogHeader: `Removing The Category all the items in the category will be deleted`,
      confirmText: "Yes, Remove",
      cancleText: "Back",
      successToastMessage: `Removed Successfully`,
    },
  },
  productsForm: {
    title: {
      add: { text: `Adding Shop Item` },
      update: { text: `Edit Shop Item` },
    },
    form: [
      {
        key: "name",
        label: "Product Name ",
        placeholder: `Ex: Laptop, mouse, server, printer...`,
        type: "textarea",
      },
      {
        key: "price",
        label: "Price",
        placeholder: `Ex: 10,000 `,
        type: "number",
      },
      {
        key: "status",
        label: "Status",
        placeholder: `Select Status`,
        type: "dropdown",
        options: [
          { value: "new", name: "New" },
          { value: "used", name: "Used" },
        ],
        defaultValue: "New",
      },
      {
        key: "brand",
        label: "Brand",
        placeholder: `Ex: Lenovo, Dell, hp...`,
        type: "text",
      },
      {
        key: "category",
        label: "Item Category",
        placeholder: `Select Category`,
        type: "dropdown",
        options: [],
      },
    ],
    addProduct: {
      successToastMessage: `Product Added Successfully`,
    },
    updateProduct: {
      successToastMessage: `Product Updated Successfully`,
    },
    addNewBullet: { icon: "pi pi-plus", text: `Add Bullet` },
    deleteProduct: {
      headerDialog: `Do you want to remove this item...`,
      confirmText: "Yes",
      cancleText: "No",
      footerDialog: `from You Shop?`,
      successToastMessage: `Product Deleted Successfully`,
    },
    button: {
      icon: "pi pi-save",
      text: `Save Item`,
    },
    leaveConfirmText: `Do you want to continue without saving?`,
    formValues: undefined,
  },
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setEditProduct(state, action) {
      return {
        ...state,
        productsForm: { ...state.productsForm, formValues: action.payload },
      };
    },
    clearEditProduct(state) {
      return {
        ...state,
        productsForm: { ...state.productsForm, formValues: undefined },
      };
    },
  },
});
export const { setEditProduct, clearEditProduct } = dataSlice.actions;
export default dataSlice.reducer;
