import React, { useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
function ToastComponent() {
  const {show,data}= useSelector(state=>state.toast)
  const showToast = ({
    detail = "error",
    severity = "error",
    summary = "Error",
  }) => {
    toast.current.show({
      severity,
      summary,
      detail,
      life: 2000,
    });
  };
  useEffect(() => {

    if (show) showToast(data);
  }, [show, data]);
  const toast = useRef(null);
  return (
    <>
      <Toast ref={toast} />
    </>
  );
}

export default ToastComponent;
