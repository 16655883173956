import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { InputTextarea } from "primereact/inputtextarea";
export const getLocalImageByName = (name) => {
  const images = require.context("../assets", true);
  return name && images(`./${name}`);
};

export const getNumberFormat = (number) => Intl.NumberFormat().format(number);

export const getStatusFromInputArray = (inputsArray) => {
  return inputsArray.reduce((a, b) => {
    a[b.key] = b.list ? [""] : "";
    return a;
  }, {});
};

export const updateFormError = (formData) => {
  return Object.keys(formData)
    .map((key) => ({ [key]: !Boolean(formData[key]) }))
    .reduce((a, b) => {
      a = { ...a, ...b };
      return a;
    }, {});
};

export const generateFormControl = (
  {
    value = "",
    key,
    label,
    placeholder = "",
    className = "",
    type,
    maxLength = "",
  },
  data,
  patchData,
  errorData,
  index = null
) => {
  const handleChange = (e) => {
    if (index == null) {
      patchData({
        ...data,
        [key]: e.target.value,
      });
    } else {
      const arrayOfValues = data[key];
      arrayOfValues[index] = e.target.value;
      patchData({
        ...data,
        [key]: arrayOfValues,
      });
    }
  };

  const deleteIndex = () => {
    const arrayOfValues = data[key];
    arrayOfValues.splice(index, 1);
    patchData({
      ...data,
      [key]: arrayOfValues,
    });
  };

  const updatedKey = index === null ? key : `${key}-${index}`;
  const cls = `${errorData[updatedKey] ? "p-invalid" : ""}`;
  let labelElement = !index && <label htmlFor={updatedKey}>{label}</label>;
  let inputElement;
  switch (type) {
    case "password":
      inputElement = (
        <Password
          onChange={handleChange}
          className={`${className} ${cls}`}
          id={updatedKey}
          placeholder={placeholder}
          feedback={false}
          toggleMask
          defaultValue={value}
          autoComplete="off"
        />
      );
      break;
    case "textarea":
      inputElement = (
        <InputTextarea
          onChange={handleChange}
          className={`${className} ${cls}`}
          id={updatedKey}
          placeholder={placeholder}
          defaultValue={value}
          autoComplete="off"
        />
      );
      break;
    default:
      inputElement = (
        <InputText
          onChange={handleChange}
          maxLength={maxLength}
          className={`${className} ${cls}`}
          id={updatedKey}
          placeholder={placeholder}
          defaultValue={value}
          autoComplete="off"
        />
      );
  }

  return (
    <div className="form-control" key={updatedKey}>
      {labelElement}
      {inputElement}
      {index !== null && (
        <button type="button" onClick={deleteIndex}>
          X
        </button>
      )}
    </div>
  );
};

export const handleErrors = (error = {}) => {
  const { status, data } = error;
  return data?.message;
};

export const isImageRequired = (images, isEdit, defaultValues) => {
  const isSelectImage = Array.isArray(images) ? images.length : images;
  const noSelectedImageInAdd = !isSelectImage && !isEdit;
  const noSelectedImageInEditWithoutPrevImageSelected =
    isEdit &&
    !isSelectImage &&
    !defaultValues.images_url &&
    !defaultValues.image_url;

  return noSelectedImageInAdd || noSelectedImageInEditWithoutPrevImageSelected;
};

export const submitAction = async ({
  data,
  isEdit,
  defaultValues,
  images,
  setImageMissing,
  update,
  add,
  addImage,
  dispatch,
  showToast,
  detail,
  setVisible,
  addedId,
  setAddedId,
}) => {
  if (isImageRequired(images, isEdit, defaultValues)) {
    setImageMissing(true);
    return;
  }
  setImageMissing(false);
  try {
    const response =
      isEdit || addedId
        ? await update({ id: defaultValues.id || addedId, body: data }).unwrap()
        : await add(data).unwrap();

    if (!isEdit && !addedId) {
      setAddedId(response._id);
    }
    if (images || (Array.isArray(images) && images.length)) {
      const imgs = !Array.isArray(images) ? [images] : images;
      // UPlaod Linear
      for (let i = 0; i < imgs.length; i++) {
        if (imgs[i]) {
          const formData = new FormData();

          formData.append("image", imgs[i]);
          await addImage({
            id: response._id || defaultValues.id,
            body: formData,
            params: { index: i },
          }).unwrap();
        }
      }
      // Upload Parallel
      // const imgReposponse = await Promise.allSettled(
      //   imgs.map((image, index) => {
      //     const formData = new FormData();
      //     formData.append("image", image);
      //     return addImage({
      //       id: response._id || defaultValues.id,
      //       body: formData,
      //       params: { index },
      //     }).unwrap();
      //   })
      // );
      // console.log(imgReposponse);
      // const errors = imgReposponse.filter(
      //   (response) => response.status === "rejected"
      // );
      // if (errors.length) {
      //   throw {
      //     data: {
      //       message: `Failed To Upload ${
      //         errors.length > 1 ? errors.length : ""
      //       } Image${errors.length > 1 ? "s" : ""}`,
      //     },
      //   };
      // }
    }
    dispatch(
      showToast({
        detail,
        severity: "success",
        summary: "Success",
      })
    );
    setVisible(false);
  } catch (err) {
    console.log(`error`, err);
    const errors = handleErrors(err);
    if (Array.isArray(errors)) {
      for (let error of errors) {
        dispatch(
          showToast({
            detail: error,
            severity: "error",
            summary: "Error",
          })
        );
      }
    } else {
      dispatch(
        showToast({
          detail: `${errors || "Error"}`,
          severity: "error",
          summary: "Error",
        })
      );
    }
  }
};
