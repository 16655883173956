const isNotLogin = (auth) => {
  // const isUserLogin = localStorage.getItem("token");
  const isUserLogin = auth?.token
  return new Promise((resolve, reject) => {
    if (isUserLogin) {
      reject(new Error("User Is Already Login in"));
    } else {
      resolve("There is No Use Loged in");
    }
  });
};

export default isNotLogin;
