import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./base";

const serviceSlice = createApi({
  reducerPath: "servicesApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["service"],
  endpoints: (builder) => ({
    getAll: builder.query({
      query: () => `service`,
      providesTags: ["service"],
    }),
    addService: builder.mutation({
      query(body) {
        return {
          url: `service`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["service"],
    }),
    updateService: builder.mutation({
      query({ id, body }) {
        return {
          url: `service/${id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["service"],
    }),
    appendImageToService: builder.mutation({
      query({ id, body }) {
        return {
          url: `service/${id}/upload_image`,
          method: "POST",
          responseHandler: "text",
          body,
        };
      },
      invalidatesTags: ["service"],
    }),
    removeService: builder.mutation({
      query(id) {
        return {
          url: `service/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["service"],
    }),
  }),
});

export default serviceSlice;
export const {
  useGetAllQuery,
  useUpdateServiceMutation,
  useAddServiceMutation,
  useAppendImageToServiceMutation,
  useRemoveServiceMutation,
} = serviceSlice;
